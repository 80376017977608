module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"toggol-website","short_name":"toggol","start_url":"/","background_color":"#1C1C1E","theme_color":"#6CB9FF","display":"minimal-ui","icon":"src/images/toggol-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"09e83bd2f5390f8a87839dc6ec9a67dc"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173356528-2","head":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PH2NDNK"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
